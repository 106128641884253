// Footer

.footer {
    padding: $footer-padding-y $footer-padding-x;
    border-left: $footer-border-width solid $footer-border-color;
    border-right: $footer-border-width solid $footer-bg;

    font-family: $font-family-sans-serif-roboto;
    color: $footer-color;
    background-color: $footer-bg;

    img {
        height: $footer-img-height;
        max-height: $footer-img-height;
    }

    a {
        font-family: inherit;
        font-weight: inherit;
        color: inherit;
    }
}
