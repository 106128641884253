// Navbar brand
//
// Used for brand, project, or site names.

.navbar-brand {
  display: flex;
  align-items: center;

  img {
    height: $navbar-brand-img-height;
    max-height: $navbar-brand-img-height;
  }
}

// Navbar nav
//
// Custom navbar navigation (doesn't require `.nav`, but does make use of `.nav-link`).

.navbar-nav {
  .nav-link {
    position: relative;
    display: inline-block;
    padding-left: $navbar-nav-link-padding-x;
    padding-right: $navbar-nav-link-padding-x;
  }

  .show>.nav-link,
  .active>.nav-link,
  .nav-link.show,
  .nav-link.active {
    font-weight: $font-weight-bold;

    .nav-link-marker {
      display: block;
    }
  }

  .nav-link-marker {
    display: none;
    position: absolute;
    bottom: calc($nav-link-padding-y - $navbar-active-border-width);
    left: $navbar-nav-link-padding-x;
    right: $navbar-nav-link-padding-x;
    height: $navbar-active-border-width;
    background: $navbar-active-border-color;
    border-radius: calc($navbar-active-border-width / 2);
  }
}

// Navbar divider
//
//

.navbar-divider {
  height: $navbar-divider-height;
  width: $navbar-divider-width;
  background-color: $navbar-divider-color;
  margin-left: $navbar-divider-padding-x;
  margin-right: $navbar-divider-padding-x;
}
