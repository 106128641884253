//
// Base styles
//

.alert {
  font-family: $font-family-sans-serif-roboto;

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    color: inherit;
  }
}

// Dismissible alerts
//
// Expand the right padding and account for the close button's positioning.

.alert-dismissible {
  .close {
    color: inherit;
    opacity: 1;

    @include hover() {
      color: inherit;
    }
  }
}
