// Base class
//
// Kickstart any navigation component with a set of style resets. Works with
// `<nav>`s, `<ul>`s or `<ol>`s.

.nav-link {
  font-weight: $nav-link-font-weight;
}

//
// Tabs
//

.nav-tabs {
  .nav-item:not(:first-of-type) {
    margin-left: 0.25rem;
  }

  .nav-link {
    border-color: $nav-tabs-border-color;
    letter-spacing: $nav-tabs-link-letter-spacing;
    color: $nav-tabs-link-color;

    &:focus,
    &.focus {
      box-shadow: $nav-tabs-link-focus-box-shadow;
    }

    &:focus-visible {
      outline: none;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    font-weight: $nav-tabs-link-active-font-weight;
  }
}
