//
// Base styles
//

.btn {
  letter-spacing: $btn-letter-spacing;
  text-transform: uppercase;
}

//
// Alternate buttons
//

@each $color,
$value in $theme-colors {
  .btn-#{$color} {
    @include hover() {
      background-color: $btn-active-bg;
    }

    &:focus,
    &.focus {
      background-color: $btn-active-bg;
    }

    &.disabled,
    &:disabled {
      color: $btn-disabled-color;
      background-color: $btn-disabled-bg;
      border-color: $btn-disabled-bg;
    }
  }
}

@each $color,
$value in $theme-colors {
  .btn-outline-#{$color} {
    font-weight: $btn-outline-font-weight;

    &.disabled,
    &:disabled {
      color: $btn-outline-disabled-color;
      border-color: $btn-outline-disabled-color;
    }
  }
}

@each $color,
$value in $theme-colors {
  .btn-flat-#{$color} {
    @include button-outline-variant($value, $value);
    border-color: transparent;

    &:hover {
      background-color: rgba($gray-400, .3);
      border-color: transparent;
    }

    &.disabled,
    &:disabled {
      background-color: transparent;
      border-color: transparent;
    }
  }
}

//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
  font-family: $font-family-sans-serif-roboto;
  font-weight: $link-font-weight;
  line-height: $link-line-height;
  letter-spacing: $link-letter-spacing;
  text-transform: inherit;
}
