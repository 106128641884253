// Checkboxes and radios
//
// Base class takes care of all the key behavioral aspects.

.custom-control {
  padding-top: $custom-control-padding-y;
  padding-bottom: $custom-control-padding-y;
}

.custom-control-input {
  &:focus ~ .custom-control-label::before {
    border-color: $custom-control-indicator-focus-border-color;
  }
}

// Switches
//
// Tweak a few things for switches

.custom-switch {
  .custom-control-input {
    & ~ .custom-control-label {
      &::before {
        background-color: $custom-switch-bg;
      }
      &::after {
        background-color: $custom-control-indicator-bg;
      }
    }

    &:checked ~ .custom-control-label::before {
      background-color: $custom-switch-active-bg;
      border-color: $custom-switch-active-border-color;
    }

    // Use [disabled] and :disabled to work around https://github.com/twbs/bootstrap/issues/28247
    &[disabled],
    &:disabled {
      & ~ .custom-control-label {
        &::before {
          background-color: $custom-switch-disabled-bg;
          border-color: $custom-switch-disabled-border-color;
        }
      }

      &:checked ~ .custom-control-label::before {
        background-color: $custom-switch-active-disabled-bg;
        border-color: $custom-switch-active-disabled-border-color;
      }
    }
  }
}
