//
// Headings
//

h1, .h1 { 
  letter-spacing: $h1-letter-spacing;
}
h2, .h2 { 
  letter-spacing: $h2-letter-spacing;
  line-height: $h2-line-height;
}
h3, .h3 {
  line-height: $h3-line-height;
}
h4, .h4 {
  font-family: $h4-font-family;
  font-weight: $h4-font-weight;
  line-height: $h4-line-height;
}

//
// Emphasis
//

small,
.small {
  font-family: $font-family-sans-serif-roboto;
  line-height: $small-line-height;
}

//
// Forms
//

label {
  text-transform: uppercase;
  color: $label-color;
  letter-spacing: $label-letter-spacing;
}
